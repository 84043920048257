.contactForm{
    color: rgb(0, 0, 170);
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0, 0, 0);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    h3{
        width: 100%;
        text-align: center;
        color: rgb(0, 0, 170);
        font-size: 30px;
        font-family: "Kalam", cursive;
        font-weight: 400;
        font-style: normal;
        margin: 0;
    }
    div {
        color: rgb(0, 0, 170);
        width: 100%;
        max-width: 100%; 
        margin-bottom: 20px;    
        label{
            color: rgb(0, 0, 170);
            display: block;
            margin-bottom: 10px;
            font-size: 1.2rem;
        }
        input, textarea{
            color: rgb(0, 0, 170);
            max-width: calc(100% - 20px);
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
        #message {
            height: 200px;
        }
    }
    
    button{
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1.2rem;
        margin-top: 20px;
        background-color: #000;
        color: #fff;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover{
            background-color: #fff;
            color: #000;
            transition: all 0.3s ease;
        }
    }
}