@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');


.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_title {
      font-family: "Kalam", cursive;
      font-weight: 400;
      font-style: normal;
      font-size: 45px;
      color: rgb(0, 0, 121);
      padding: 0;
      margin: 0;
    }
    &_me {
      padding: 50px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      @include respond-to ('medium') {
        justify-content: center;
        flex-direction: column;
        gap: 50px;
      }
      .meImg{
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 50% 10% 50% 10%;

        box-shadow: 2px 2px 10px white;

        &:hover {
          transform: scale(1.1);
          transition: transform 0.3s;
        }
      }
      &_text {
        width: 350px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 20px;
        text-align: center;
        color: rgb(0, 0, 170);
        padding: 0 ;
        margin: 0;
        @include respond-to ('huge') {
          width: 500px;
        }
        @include respond-to ('medium') {
          padding: 0;
          width: 100%;
        }
        @include respond-to ('small') {
          font-size: 15px;
          width: 85%;
          margin-top: 20px;

        }
      }
    }
  }