.splitBanner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;
    width: 100%;
    height: 150px;
    &_title {
      font-family: "Kalam", cursive;
      font-weight: 400;
      font-style: normal;

      font-size: 45px;
      color: rgb(0, 0, 121);
    }
    &_img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 150px;
      object-fit: cover;
      filter: blur(2px);
      z-index: -1;
    }
}