.diaporama {
    width: 100%;
    
    .swiper {
        width: 100%;
        .swiper-wrapper{
            width: 100%;
            .swiper-slide{
                width: 100%;
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}