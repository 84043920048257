@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

@import './styles/mixins.scss';

@import './Home/Home.scss';
@import './components/Header/Header.scss';
@import './components/Footer/Footer.scss';
@import './components/About/About.scss';
@import './components/Diaporama/Diaporama.scss';
@import './components/SplitBanner/SplitBanner.scss' ;
@import './components/Techno/Techno.scss';
@import './components/Works/Works.scss';
@import './components/SwiperNavButtons/SwiperNavButtons.scss';
@import './components/ContactForm/ContactForm.scss';

body, html {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  .header{
    width: 100%;
    height: 300px;
    @include respond-to ('medium') {
      height: 200px;
    }
  }
}
