@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

.header {
    position: relative;
    width: 100%;
    height: 100%;

    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;

    .backgroundImg {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;

        object-fit: cover;
        filter: blur(4px);

        z-index: -1;
    }
    .title {
        width: 100%;
        margin-left: 50px;
        color: rgb(0, 0, 36);
        font-size: 100px;

        font-family: "Kalam", cursive;
        font-weight: 400;
        font-style: normal;

        position: relative;
        @include respond-to ('huge') {
            font-size: 60px;
        }
        @include respond-to ('large') {
            font-size: 50px;
        }
        @include respond-to ('medium') {
            font-size: 40px;
        }
        &-name{
            text-shadow: white 5px 5px 2px;
            z-index: 1;
        }
        &-job {
            width: 100%;
            top: 80px;
            left: 355px;
            position: absolute;
            text-shadow: white 5px 5px 2px;
            z-index: -1;
            @include respond-to ('huge') {
                left: 250px;
            }
            @include respond-to ('large') {
                left: 200px;
            }
            @include respond-to ('medium') {
                font-size: 35px;
                top: 40px;
                left: 20px;
            }
            @include respond-to ('small') {
                font-size: 28px;
            }
        }
    }
}