@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

@import '../styles/mixins.scss';

.home {
  width: 100%;
  .aboutMe{
    margin: 0;
    padding: 50px 25%;
    height: auto;
    @include respond-to ('huge') {
      padding: 50px 100px;
    }
    @include respond-to ('medium') {
      padding: 50px 0;
    }
  }
  .works {
    margin: 0;
    padding: 50px 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 100px;
    @include respond-to ('huge') {
      padding: 50px 100px;
    }
    @include respond-to ('medium') {
      padding: 50px 0;
      width: 100%;
    }
    div{
      width: 100%;
      padding: 0;
      @include respond-to ('medium') {
        width: 100%;
      }
    }
  }
  .skills {
    margin: 0;
    padding: 50px 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    @include respond-to ('huge') {
      padding: 50px 100px;
    }
    &_under {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 50px;
      align-items: center;
      justify-content: center;
      div {
        width: 50px;
      }
    }
  }
  .contact {
    margin: 0;
    padding: 50px 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    @include respond-to ('huge') {
      padding: 50px 100px;
    } 
    @include respond-to ('medium') {
      padding: 50px 0;
    }
    .contact_form{
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    } 
  }
}


@keyframes home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
