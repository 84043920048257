.swiper-nav-buttons {
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 10;
    @include respond-to('medium') {
        display: none;
    }
    button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        width: 50px;
        height: 50px;
    }
    .prev-btn {
        position: absolute;
        left: 0;
        height: 100%;
        width: 50%;
        top: 50%;
        transform: translateY(-50%);
        svg {
            transform: rotate(180deg);
            position: absolute;
            left: 0;
        }
    }
    .next-btn {
        position: absolute;
        right: 0;
        height: 100%;
        width: 50%;
        top: 50%;
        transform: translateY(-50%);
        svg {
            position: absolute;
            right: 0;
        }
    }
}