@import '../../styles/mixins.scss';
.work-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 10px;
    border-radius: 5px;
    @include respond-to ('medium') {
        padding: 0;
    }
    &_title {
        text-align: center;
        color: rgb(0, 0, 121);
        font-size: 25px;
    }
    &_preview {
        width: 100%;
        border-radius: 5px;
        &:hover {
            transform: scale(1.1);
            transition: transform 0.3s;
            cursor: pointer;
        }
    }
    &_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 20px;
        margin-top: 50px;
        &_readme {
            color: rgb(0, 0, 170);
            text-align: center;
        }
        &_link {
            color: rgb(0, 0, 170);
            text-decoration: none;
            font-size: 18px;
        }
    }
    
}